import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { StaticQuery, graphql } from "gatsby";
import Layout from "/src/components/Layout";
import TravelCard from "/src/components/TravelCard";
export const _frontmatter = {
  "title": "Explore Indianapolis",
  "cardImage": "../../images/travel/ExploreIndianapolis.jpg",
  "order": 4
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Grid = makeShortcode("Grid");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <StaticQuery query={graphql`
    query {
      allMdx(
        filter: { slug: { glob: "travel/explore-indianapolis/*" } }
        sort: { fields: frontmatter___order }
      ) {
        nodes {
          slug
          frontmatter {
            cardImage {
              childImageSharp {
                gatsbyImageData(
                  width: 320
                  height: 170
                  layout: CONSTRAINED
                  transformOptions: {cropFocus: CENTER}
                  quality: 75
                )
              }
            }
            title
          }
        }
      }
    }
  `} render={data => {
      return <Layout title="Explore Indianapolis" mdxType="Layout">
        <h1 style={{
          "textAlign": "center"
        }}>Explore Indianapolis</h1>
        <Grid columns="2" mdxType="Grid">
          {data.allMdx?.nodes?.map(({
            frontmatter,
            slug
          }) => <TravelCard key={slug} href={"/" + slug} title={frontmatter?.title} image={frontmatter?.cardImage?.childImageSharp.gatsbyImageData} mdxType="TravelCard" />)}
        </Grid>
      </Layout>;
    }} mdxType="StaticQuery"></StaticQuery>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      